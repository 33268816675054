import React from 'react';
import Section from '../../common/Section';
import { FormattedMessage } from "react-intl";

const VideoHeader = ({youtubeurl, titleText, descriptionText, backimg, htmlText}) => {
    return (
        <Section sectionClasses="py-0 text-center position-relative min-vh-100" childClasses='bg-holder header-video' childStyle={{ backgroundImage: "url(" + backimg + ")" }}>
            <div className="video-wrapper position-absolute w-100 h-100">
                <iframe
                    title="video introduction"
                    frameBorder="0"
                    height="100%"
                    width="100%"
                    src={youtubeurl}
                >
                </iframe>
            </div>
            <div className="min-vh-100 content-wrapper d-flex flex-column justify-content-center text-white container">
                <div style={{ "width": "100%", "height": "100vh", "background": "transparent", "position": "absolute", "left": "0", "zIndex": "1" }}></div>
                <h1 style={{ "zIndex": "2" }}><FormattedMessage id={titleText} defaultMessage={titleText}/></h1>
                {descriptionText &&
                    <p style={{ "zIndex": "2" }} className="col-8 offset-2 text-center"><FormattedMessage id={descriptionText} defaultMessage={descriptionText} /></p>
                }
                {htmlText && <p style={{ "zIndex": "2" }} className="col-8 offset-2 text-center" dangerouslySetInnerHTML={{ __html: htmlText }} />}
            </div>
        </Section>
    )
};

export default VideoHeader;