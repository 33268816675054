import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Section from "../../common/Section";

const WinesMenu = () => {
	const data = useStaticQuery(graphql`
		query {
			allWinesmenuJson {
				nodes {
					name
					pathId
					src {
							id
							publicURL
						}
				}
			}
		}
	`)

	return (
		<Section sectionClasses={'py-0 text-center position-relative min-vh-100'} childClasses={'bg-holder'} childStyle={{background: "#EEEEEE"}}>
			<div className="row justify-content-md-center vinos-menu">
				<div className="container min-vh-100 content-wrapper d-flex flex-row justify-content-center align-items-center gap-3">
					{data.allWinesmenuJson.nodes.map((wine,i) => (
						<div className="col-md-2 item-vinos" key={i} style={{maxWidth: '196px'}}>
							<div className="border border-warning h-100">
								<a href={'#'+wine.pathId}><img src={wine.src.publicURL} alt={wine.name} /></a>
								<p className="text-center"><strong>{wine.name}</strong></p>
							</div>
						</div>
					))}
				</div>
			</div>
		</Section>
	)
}

export default WinesMenu