import React,{useState, useEffect} from "react"
import Layout from "../components/layout"
import img01 from "../images/vinos-01.jpg"
import DesktopWinesMenu from "../components/vinos/desktop/winesMenu";
import MobileWinesMenu from '../components/vinos/mobile/WinesMenu';
import DesktopVinosDetalle from "../components/vinos/desktop/VinosDetalle";
import MobileVinosDetalle from '../components/vinos/mobile/VinosDetalle';
import SimpleLocalize from "../components/SimpleLocalize";
import DesktopVideoHeader from "../components/common/desktop/VideoHeader";
import MobileVideoHeader from "../components/common/mobile/VideoHeader";

const IndexPage = (props) => {
	const [isMobile, setIsMobile] = useState(false);
	useEffect(() => {
		if (typeof window !== "undefined") {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		handleResize();

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
		}
	}, []);
  const isDesktop = !isMobile;

	return(
		<SimpleLocalize {...props}>
			<Layout {...props}>
				{isDesktop ?
					<>
						<DesktopVideoHeader
							youtubeurl={'https://youtube.com/embed/M96Jx8NOaGk?autoplay=1&controls=0&showinfo=0&autohide=1&rel=0&loop=1&mute=1&playlist=M96Jx8NOaGk&modestbranding=1'}
							backimg={img01}
							titleText={'vinos-title'}
							descriptionText={'el-terroir-que-envuelve'}
						/>
						<DesktopWinesMenu {...props} />
						<DesktopVinosDetalle {...props}/>
					</>:<>
						<MobileVideoHeader
							youtubeurl={'https://youtube.com/embed/M96Jx8NOaGk?autoplay=1&controls=0&showinfo=0&autohide=1&rel=0&loop=1&mute=1&playlist=M96Jx8NOaGk&modestbranding=1'}
							backimg={img01}
							titleText={'vinos-title'}
							descriptionText={'el-terroir-que-envuelve'}
						/>
						<MobileWinesMenu {...props} />
						<MobileVinosDetalle {...props}/>
					</>
				}
			</Layout>
		</SimpleLocalize>
	)
}

export default IndexPage