import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Section from "../../common/Section";

const WinesMenu = () => {
	const data = useStaticQuery(graphql`
		query {
			allWinesmenuJson {
				nodes {
					name
					pathId
					src {
							id
							publicURL
						}
				}
			}
		}
	`)

    const linkEvent = (pathId) => {
        document.querySelector(`#${pathId}`).scrollIntoView({
            behavior: 'smooth'
       });
    }

	return (
		<Section sectionClasses={'py-0 text-center position-relative wine-menu-mobile'} childStyle={{background: "#EEEEEE"}}>
			<div className="row justify-content-md-center vinos-menu">
				<div className="container mt-3 d-flex flex-row justify-content-center align-items-center gap-3">
                    {data.allWinesmenuJson.nodes.slice(0, 3).map((wine, i) => (
                        <div className="col-md-2 item-vinos" key={i} style={{ maxWidth: '196px' }}>
                            <div className="border border-warning h-100">
                                <button onClick={()=>{linkEvent(wine.pathId)}}><img src={wine.src.publicURL} alt={wine.name} /></button>
                                <p className="text-center"><strong>{wine.name}</strong></p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="container mt-3 mb-3 d-flex flex-row justify-content-center align-items-center gap-3">
                    {data.allWinesmenuJson.nodes.slice(3).map((wine, i) => (
                        <div className="col-md-2 item-vinos" key={i + 3} style={{ maxWidth: '196px' }}>
                            <div className="border border-warning h-100">
                                <button onClick={()=>{linkEvent(wine.pathId)}}><img src={wine.src.publicURL} alt={wine.name} /></button>
                                <p className="text-center"><strong>{wine.name}</strong></p>
                            </div>
                        </div>
                    ))}
				</div>
			</div>
		</Section>
	)
}

export default WinesMenu